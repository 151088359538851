<template>
  <v-container fluid>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab v-for="item in items" :key="item.tab" :to="`#${item.tab}`">
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab" :id="item.tab">
          <component v-bind:is="item.content"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'

import Orders from '@/components/retail-service/Orders';
import RetailService from '@/components/retail-service/RetailService';

export default Vue.extend({
  components: {
    Orders,
    RetailService
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: 'orders', name: 'Orders', content: Orders },
        { tab: 'retail-service', name: 'Retail Service', content: RetailService }
      ]
    };
  }
});
</script>
