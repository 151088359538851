











import { CustomerApiFp, RetailPlanApiFp } from '@/api/api'
import Vue from 'vue'
export default Vue.extend({
  name:'customer-autocomplete',
  data(){
    return{
      temp: '',
      isLoading: true,
      items: []
    }
  },
  model:{
    prop: 'customer',
    event: 'change'
  },
  async mounted(){
    this.$data.items = (await CustomerApiFp().operatorCustomersGet()()).map( cust => { return { text: cust.fullName, value: cust.id} } );
    this.isLoading = false;
  }
})
