











import { UnitApiFp } from '@/api/api'
import Vue from 'vue'
export default Vue.extend({
  name:'address-autocomplete',
  data(){
    return{
      temp: '',
      isLoading: true,
      items: []
    }
  },
  model:{
    prop: 'address',
    event: 'change'
  },
  async mounted(){
    this.$data.items = (await UnitApiFp().operatorUnitsGet()()).map( unit => { return {text: unit.address, value: unit.id} })
    this.isLoading = false;
  }
})
