


































































































































import { BASE_PATH } from '@/api/api'
import Vue from 'vue'
import AddressAutocomplete from '../forms/AddressAutocomplete.vue'
import CustomerAutocomplete from '../forms/CustomerAutocomplete.vue'
import RetailPlanAutocomplete from '../forms/RetailPlanAutocomplete.vue'
export default Vue.extend({
  components: { AddressAutocomplete, RetailPlanAutocomplete, CustomerAutocomplete },
  name: 'NewConnection',
  data () {
    return {
      isLoading: false,
      tabPaymentMethod: '',

      inputDirectBSB: '',
      inputDirectName: '',
      inputDirectAccountNo: '',


      menuScheduleDate: '',
      inputCustomer: '' as any,
      inputScheduleDate: '',
      inputAddress: '',
      inputRetailPlan: '',

      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
  methods: {
    async submit(){
      this.isLoading = true;
      const newCustomer = this.inputCustomer.value != undefined ? false : true;
      let res = await (await fetch(BASE_PATH + '/operator/dummy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          newCustomer: newCustomer,
          addressId: this.inputAddress,
          retailPlanId: this.inputRetailPlan,
          scheduleDate: this.inputScheduleDate,
          customer: newCustomer ? this.inputCustomer : this.inputCustomer.value
        })
      })).json()
      this.$router.go(0);
    }
  }
})
