































//TODO vuevalidate
 import Vue from 'vue';
 import { AuthApiFp, RequiredError } from '@/api/api'
 import router from '@/router';

 export default Vue.extend({
   data: () => ({
     showError: false,
     msgError: '',
     loading: false,
     user: {
       email: '',
       password: '',
     }
   }),
   methods:{
     async login(){
       this.loading =! this.loading;
       try{
         let res = await AuthApiFp().authLoginPost({
           email: this.user.email,
           password: this.user.password
         })();
         localStorage.setItem('jwt', res.accessToken);
         this.$router.push('/')
       } catch (e) {
         let error: RequiredError = await e;
         this.showError = true;
         this.msgError = error.error;
       }
       this.loading =! this.loading;
     }
   }
 })
