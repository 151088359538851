/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * RSPME System API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
// import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

let apiEndpoint = '';
if(process.env.NODE_ENV == 'development'){
    apiEndpoint = 'http://localhost:8000'
  } else {
    apiEndpoint = 'https://test-app-redtrain.herokuapp.com'
}

export const BASE_PATH = apiEndpoint.replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @interface RequiredError
 */
export interface RequiredError {
    error: string
}

/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * 
     * @type {number}
     * @memberof BankAccount
     */
    bsb?: number;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountNo?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    displayWithMask?: string;
}
/**
 * 
 * @export
 * @interface BearerToken
 */
export interface BearerToken {
    /**
     * 
     * @type {string}
     * @memberof BearerToken
     */
    accessToken: string;
    /**
     * 
     * @type {number}
     * @memberof BearerToken
     */
    expiresIn: number;
    /**
     * 
     * @type {string}
     * @memberof BearerToken
     */
    tokenType: BearerToken.TokenTypeEnum;
}

/**
 * @export
 * @namespace BearerToken
 */
export namespace BearerToken {
    /**
     * @export
     * @enum {string}
     */
    export enum TokenTypeEnum {
        Bearer = <any> 'bearer'
    }
}
/**
 * 
 * @export
 * @interface BillingAccount
 */
export interface BillingAccount {
    /**
     * 
     * @type {number}
     * @memberof BillingAccount
     */
    id?: number;
    /**
     * unique account number for payment
     * @type {string}
     * @memberof BillingAccount
     */
    accountNo?: string;
    /**
     * 
     * @type {number}
     * @memberof BillingAccount
     */
    unitId?: number;
    /**
     * 
     * @type {UnitLite}
     * @memberof BillingAccount
     */
    unit?: UnitLite;
    /**
     * 
     * @type {number}
     * @memberof BillingAccount
     */
    customerId?: number;
    /**
     * 
     * @type {CustomerLite}
     * @memberof BillingAccount
     */
    customer?: CustomerLite;
    /**
     * 
     * @type {number}
     * @memberof BillingAccount
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingAccount
     */
    totalReceived?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingAccount
     */
    status?: BillingAccount.StatusEnum;
}

/**
 * @export
 * @namespace BillingAccount
 */
export namespace BillingAccount {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Enabled = <any> 'enabled',
        Disabled = <any> 'disabled'
    }
}
/**
 * 
 * @export
 * @interface AuthLoginBody
 */
export interface AuthLoginBody {
    /**
     * 
     * @type {string}
     * @memberof AuthLoginBody
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthLoginBody
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface AuthChangePasswordBody
 */
export interface AuthChangePasswordBody {
    /**
     * 
     * @type {string}
     * @memberof AuthChangePasswordBody
     */
    oldPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthChangePasswordBody
     */
    newPassword?: string;
}
/**
 * 
 * @export
 * @interface AuthResetPassword
 */
export interface AuthResetPassword {
    /**
     * 
     * @type {string}
     * @memberof AuthResetPassword
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface AuthChangePasswordWithTokenBody
 */
export interface AuthChangePasswordWithTokenBody {
    /**
     * 
     * @type {string}
     * @memberof AuthChangePasswordWithTokenBody
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthChangePasswordWithTokenBody
     */
    newPassword?: string;
}
/**
 * 
 * @export
 * @interface RetailOrderCancelBody
 */
export interface RetailOrderCancelBody {
    /**
     * 
     * @type {string}
     * @memberof RetailOrderCancelBody
     */
    reason?: string;
}
/**
 * 
 * @export
 * @interface RetailPlanUpdateBody
 */
export interface RetailPlanUpdateBody {
    /**
     * 
     * @type {string}
     * @memberof RetailPlanUpdateBody
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailPlanUpdateBody
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof RetailPlanUpdateBody
     */
    discount?: number;
}
/**
 * 
 * @export
 * @interface RetailPlanDeactivateBody
 */
export interface RetailPlanDeactivateBody {
    /**
     * 
     * @type {string}
     * @memberof RetailPlanDeactivateBody
     */
    reason?: string;
}
/**
 * 
 * @export
 * @interface UnitUpdateBody
 */
export interface UnitUpdateBody {
    /**
     * 
     * @type {boolean}
     * @memberof UnitUpdateBody
     */
    occupied?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UnitUpdateBody
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitUpdateBody
     */
    contaceEmail?: string;
}
/**
 * 
 * @export
 * @interface Building
 */
export interface Building extends BuildingLite {
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    precinctId?: number;
    /**
     * 
     * @type {PrecinctLite}
     * @memberof Building
     */
    precinct?: PrecinctLite;
    /**
     * 
     * @type {Array<number>}
     * @memberof Building
     */
    managerIds?: Array<number>;
    /**
     * 
     * @type {Array<Manager>}
     * @memberof Building
     */
    managers?: Array<Manager>;
}
/**
 * 
 * @export
 * @interface BuildingLite
 */
export interface BuildingLite {
    /**
     * 
     * @type {number}
     * @memberof BuildingLite
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingLite
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingLite
     */
    address?: string;
}
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    holderName?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    cardNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    expiredDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    cvv?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    displayWithMask?: string;
}
/**
 * 
 * @export
 * @interface Creator
 */
export interface Creator {
    /**
     * 
     * @type {number}
     * @memberof Creator
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    email?: string;
}
/**
 * end customer
 * @export
 * @interface Customer
 */
export interface Customer extends CustomerLite {
    /**
     * 
     * @type {CustomerStatus}
     * @memberof Customer
     */
    status?: CustomerStatus;
}
/**
 * end customer
 * @export
 * @interface CustomerLite
 */
export interface CustomerLite {
    /**
     * 
     * @type {number}
     * @memberof CustomerLite
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerLite
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerLite
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerLite
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerLite
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerLite
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerLite
     */
    postAddress?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerStatus {
    CreatePending = <any> 'create-pending',
    Active = <any> 'active',
    Inactive = <any> 'inactive'
}
/**
 * fault
 * @export
 * @interface Fault
 */
export interface Fault {
    /**
     * 
     * @type {number}
     * @memberof Fault
     */
    id?: number;
    /**
     * 
     * @type {Creator}
     * @memberof Fault
     */
    creator?: Creator;
    /**
     * 
     * @type {string}
     * @memberof Fault
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Fault
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Fault
     */
    content?: string;
    /**
     * 
     * @type {FaultStatus}
     * @memberof Fault
     */
    status?: FaultStatus;
    /**
     * 
     * @type {string}
     * @memberof Fault
     */
    remarks?: string;
    /**
     * 
     * @type {Date}
     * @memberof Fault
     */
    createAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Fault
     */
    closeAt?: Date;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FaultStatus {
    Open = <any> 'open',
    Closed = <any> 'closed'
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    email?: string;
    /**
     * 
     * @type {any}
     * @memberof InlineResponse200
     */
    rights?: any;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    message?: string;
}
/**
 * precinct or building manager, it shall be an user of the operator
 * @export
 * @interface Manager
 */
export interface Manager {
    /**
     * 
     * @type {number}
     * @memberof Manager
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Manager
     */
    fullName?: string;
}
/**
 * notification
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    createAt?: Date;
    /**
     * 
     * @type {Creator}
     * @memberof Notification
     */
    creator?: Creator;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    read?: boolean;
}
/**
 * 
 * @export
 * @interface OperatorInvoice
 */
export interface OperatorInvoice {
    /**
     * 
     * @type {number}
     * @memberof OperatorInvoice
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorInvoice
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorInvoice
     */
    dueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorInvoice
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorInvoice
     */
    pdfUrl?: number;
}
/**
 * user
 * @export
 * @interface OperatorUser
 */
export interface OperatorUser {
    /**
     * 
     * @type {number}
     * @memberof OperatorUser
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorUser
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorUser
     */
    role?: OperatorUser.RoleEnum;
}

/**
 * @export
 * @namespace OperatorUser
 */
export namespace OperatorUser {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        AdminOperator = <any> 'admin-operator',
        Operator = <any> 'operator'
    }
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    paymentMethod?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    amount?: number;
    /**
     * additional text in the payment
     * @type {string}
     * @memberof Payment
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    billingAccountId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    date?: Date;
}
/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    type?: PaymentMethod.TypeEnum;
    /**
     * 
     * @type {Card | BankAccount}
     * @memberof PaymentMethod
     */
    payment?: Card | BankAccount;
}

/**
 * @export
 * @namespace PaymentMethod
 */
export namespace PaymentMethod {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        DirectDebit = <any> 'direct-debit',
        Card = <any> 'card'
    }
}
/**
 * precinct
 * @export
 * @interface Precinct
 */
export interface Precinct {
    /**
     * 
     * @type {number}
     * @memberof Precinct
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Precinct
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Precinct
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Precinct
     */
    description?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Precinct
     */
    managerIds?: Array<number>;
    /**
     * 
     * @type {Array<Manager>}
     * @memberof Precinct
     */
    managers?: Array<Manager>;
}
/**
 * precinct
 * @export
 * @interface PrecinctLite
 */
export interface PrecinctLite {
    /**
     * 
     * @type {number}
     * @memberof PrecinctLite
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PrecinctLite
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PrecinctLite
     */
    address?: string;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report extends Array<ReportRecord> {
}
/**
 * 
 * @export
 * @interface ReportRecord
 */
export interface ReportRecord {
    /**
     * 
     * @type {string}
     * @memberof ReportRecord
     */
    month?: string;
    /**
     * 
     * @type {ReportRecordSales}
     * @memberof ReportRecord
     */
    sales?: ReportRecordSales;
    /**
     * 
     * @type {ReportRecordUnit}
     * @memberof ReportRecord
     */
    unit?: ReportRecordUnit;
    /**
     * 
     * @type {ReportRecordConnection}
     * @memberof ReportRecord
     */
    connection?: ReportRecordConnection;
}
/**
 * 
 * @export
 * @interface ReportRecordConnection
 */
export interface ReportRecordConnection {
    /**
     * 
     * @type {number}
     * @memberof ReportRecordConnection
     */
    activation?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecordConnection
     */
    cancellation?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecordConnection
     */
    netGain?: number;
}
/**
 * 
 * @export
 * @interface ReportRecordSales
 */
export interface ReportRecordSales {
    /**
     * 
     * @type {number}
     * @memberof ReportRecordSales
     */
    sales?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecordSales
     */
    profit?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecordSales
     */
    profitKpi?: number;
}
/**
 * 
 * @export
 * @interface ReportRecordUnit
 */
export interface ReportRecordUnit {
    /**
     * 
     * @type {number}
     * @memberof ReportRecordUnit
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecordUnit
     */
    connected?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecordUnit
     */
    unconnected?: number;
}
/**
 * 
 * @export
 * @interface ResponseError
 */
export interface ResponseError {
    /**
     * 
     * @type {string}
     * @memberof ResponseError
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseError
     */
    message: string;
    /**
     * 
     * @type {any}
     * @memberof ResponseError
     */
    errors?: any;
}
/**
 * 
 * @export
 * @interface RetailInvoice
 */
export interface RetailInvoice {
    /**
     * 
     * @type {number}
     * @memberof RetailInvoice
     */
    id?: number;
    /**
     * 
     * @type {UnitLite}
     * @memberof RetailInvoice
     */
    unit?: UnitLite;
    /**
     * 
     * @type {string}
     * @memberof RetailInvoice
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailInvoice
     */
    dueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof RetailInvoice
     */
    previousBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailInvoice
     */
    paymentReceived?: number;
    /**
     * 
     * @type {Array<RetailInvoiceItems>}
     * @memberof RetailInvoice
     */
    items?: Array<RetailInvoiceItems>;
    /**
     * 
     * @type {number}
     * @memberof RetailInvoice
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailInvoice
     */
    pdfUrl?: number;
}
/**
 * 
 * @export
 * @interface RetailInvoiceItems
 */
export interface RetailInvoiceItems {
    /**
     * 
     * @type {string}
     * @memberof RetailInvoiceItems
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailInvoiceItems
     */
    price?: string;
    /**
     * 15-12-2020 ~ 31-12-2020
     * @type {string}
     * @memberof RetailInvoiceItems
     */
    specification?: string;
    /**
     * 
     * @type {number}
     * @memberof RetailInvoiceItems
     */
    amount?: number;
}
/**
 * retail order
 * @export
 * @interface RetailOrder
 */
export interface RetailOrder {
    /**
     * 
     * @type {number}
     * @memberof RetailOrder
     */
    id?: number;
    /**
     * 
     * @type {RetailOrderType}
     * @memberof RetailOrder
     */
    type?: RetailOrderType;
    /**
     * 
     * @type {string}
     * @memberof RetailOrder
     */
    scheduleDate?: string;
    /**
     * 
     * @type {RetailOrderStatus}
     * @memberof RetailOrder
     */
    status?: RetailOrderStatus;
    /**
     * 
     * @type {number}
     * @memberof RetailOrder
     */
    unitId?: number;
    /**
     * 
     * @type {UnitLite}
     * @memberof RetailOrder
     */
    unit?: UnitLite;
    /**
     * 
     * @type {number}
     * @memberof RetailOrder
     */
    customerId?: number;
    /**
     * 
     * @type {CustomerLite}
     * @memberof RetailOrder
     */
    customer?: CustomerLite;
    /**
     * 
     * @type {number}
     * @memberof RetailOrder
     */
    oldPlanId?: number;
    /**
     * 
     * @type {RetailPlanLite}
     * @memberof RetailOrder
     */
    oldPlan?: RetailPlanLite;
    /**
     * 
     * @type {number}
     * @memberof RetailOrder
     */
    newPlanId?: number;
    /**
     * 
     * @type {RetailPlanLite}
     * @memberof RetailOrder
     */
    newPlan?: RetailPlanLite;
    /**
     * for new connection with existing method
     * @type {number}
     * @memberof RetailOrder
     */
    paymentMethodId?: number;
    /**
     * for new connection with new method
     * @type {PaymentMethod}
     * @memberof RetailOrder
     */
    paymentMethod?: PaymentMethod;
    /**
     * only for new connection
     * @type {string}
     * @memberof RetailOrder
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailOrder
     */
    closeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailOrder
     */
    remarks?: string;
}
/**
 * new retail order complex structure
 * @export
 * @interface RetailOrderNew
 */
export interface RetailOrderNew {
    /**
     * 
     * @type {string}
     * @memberof RetailOrderNew
     */
    scheduleDate?: string;
    /**
     * 
     * @type {number}
     * @memberof RetailOrderNew
     */
    unitId?: number;
    /**
     * 
     * @type {CustomerLite}
     * @memberof RetailOrderNew
     */
    customer?: CustomerLite;
    /**
     * 
     * @type {number}
     * @memberof RetailOrderNew
     */
    newPlanId?: number;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof RetailOrderNew
     */
    paymentMethod?: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof RetailOrderNew
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailOrderNew
     */
    remarks?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RetailOrderStatus {
    Verifying = <any> 'verifying',
    Confirming = <any> 'confirming',
    Active = <any> 'active',
    Cancelled = <any> 'cancelled',
    Completed = <any> 'completed'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RetailOrderType {
    NewConnection = <any> 'new-connection',
    Disconnection = <any> 'disconnection',
    ChangePlan = <any> 'change-plan'
}
/**
 * retail plan
 * @export
 * @interface RetailPlan
 */
export interface RetailPlan extends RetailPlanLite {
    /**
     * 
     * @type {number}
     * @memberof RetailPlan
     */
    wholesalePlanId?: number;
    /**
     * 
     * @type {WholesalePlanLite}
     * @memberof RetailPlan
     */
    wholesalePlan?: WholesalePlanLite;
    /**
     * 
     * @type {number}
     * @memberof RetailPlan
     */
    discount?: number;
    /**
     * 
     * @type {string}
     * @memberof RetailPlan
     */
    scopeType?: RetailPlan.ScopeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RetailPlan
     */
    scopeId?: number;
    /**
     * 
     * @type {RetailPlanScope}
     * @memberof RetailPlan
     */
    scope?: RetailPlanScope;
    /**
     * 
     * @type {RetailPlanStatus}
     * @memberof RetailPlan
     */
    status?: RetailPlanStatus;
}

/**
 * @export
 * @namespace RetailPlan
 */
export namespace RetailPlan {
    /**
     * @export
     * @enum {string}
     */
    export enum ScopeTypeEnum {
        Precinct = <any> 'precinct',
        Building = <any> 'building',
        Unit = <any> 'unit'
    }
}
/**
 * retail plan
 * @export
 * @interface RetailPlanLite
 */
export interface RetailPlanLite {
    /**
     * 
     * @type {number}
     * @memberof RetailPlanLite
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RetailPlanLite
     */
    name?: string;
    /**
     * 
     * @type {WholesalePlanContractTerm}
     * @memberof RetailPlanLite
     */
    contractTerm?: WholesalePlanContractTerm;
    /**
     * 
     * @type {number}
     * @memberof RetailPlanLite
     */
    standardPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailPlanLite
     */
    retailPrice?: number;
    /**
     * 
     * @type {WholesalePlanInvoiceFrequency}
     * @memberof RetailPlanLite
     */
    invoiceFrequency?: WholesalePlanInvoiceFrequency;
    /**
     * 
     * @type {string}
     * @memberof RetailPlanLite
     */
    description?: string;
}
/**
 * retail plan scope, can be Precinct, Building or Unit
 * @export
 * @interface RetailPlanScope
 */
export interface RetailPlanScope {
    /**
     * 
     * @type {number}
     * @memberof RetailPlanScope
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RetailPlanScope
     */
    name?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RetailPlanStatus {
    Active = <any> 'active',
    Inactive = <any> 'inactive'
}
/**
 * retail service
 * @export
 * @interface RetailService
 */
export interface RetailService {
    /**
     * 
     * @type {number}
     * @memberof RetailService
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailService
     */
    unitId?: number;
    /**
     * 
     * @type {UnitLite}
     * @memberof RetailService
     */
    unit?: UnitLite;
    /**
     * 
     * @type {number}
     * @memberof RetailService
     */
    customerId?: number;
    /**
     * 
     * @type {CustomerLite}
     * @memberof RetailService
     */
    customer?: CustomerLite;
    /**
     * 
     * @type {number}
     * @memberof RetailService
     */
    planId?: number;
    /**
     * 
     * @type {RetailPlanLite}
     * @memberof RetailService
     */
    plan?: RetailPlanLite;
    /**
     * 
     * @type {number}
     * @memberof RetailService
     */
    paymentMethodId?: number;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof RetailService
     */
    paymentMethod?: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof RetailService
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailService
     */
    endDate?: string;
    /**
     * 
     * @type {RetailServiceStatus}
     * @memberof RetailService
     */
    status?: RetailServiceStatus;
}
/**
 * retail service detail
 * @export
 * @interface RetailServiceDetail
 */
export interface RetailServiceDetail {
    /**
     * 
     * @type {number}
     * @memberof RetailServiceDetail
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailServiceDetail
     */
    retailServiceId?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailServiceDetail
     */
    unitId?: number;
    /**
     * 
     * @type {UnitLite}
     * @memberof RetailServiceDetail
     */
    unit?: UnitLite;
    /**
     * 
     * @type {string}
     * @memberof RetailServiceDetail
     */
    pppoeAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailServiceDetail
     */
    pppoePassword?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailServiceDetail
     */
    wifiRouteAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailServiceDetail
     */
    wifiRoutePassword?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailServiceDetail
     */
    wifiSsid?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailServiceDetail
     */
    wifiPassword?: string;
}
/**
 * retail service lite
 * @export
 * @interface RetailServiceLite
 */
export interface RetailServiceLite {
    /**
     * 
     * @type {number}
     * @memberof RetailServiceLite
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailServiceLite
     */
    unitId?: number;
    /**
     * 
     * @type {UnitLite}
     * @memberof RetailServiceLite
     */
    unit?: UnitLite;
    /**
     * 
     * @type {number}
     * @memberof RetailServiceLite
     */
    customerId?: number;
    /**
     * 
     * @type {CustomerLite}
     * @memberof RetailServiceLite
     */
    customer?: CustomerLite;
    /**
     * 
     * @type {number}
     * @memberof RetailServiceLite
     */
    planId?: number;
    /**
     * 
     * @type {RetailPlanLite}
     * @memberof RetailServiceLite
     */
    plan?: RetailPlanLite;
    /**
     * 
     * @type {string}
     * @memberof RetailServiceLite
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailServiceLite
     */
    endDate?: string;
    /**
     * 
     * @type {RetailServiceStatus}
     * @memberof RetailServiceLite
     */
    status?: RetailServiceStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RetailServiceStatus {
    Active = <any> 'active',
    Inactive = <any> 'inactive'
}
/**
 * 
 * @export
 * @interface Unit
 */
export interface Unit extends UnitLite {
    /**
     * 
     * @type {PrecinctLite}
     * @memberof Unit
     */
    precinct?: PrecinctLite;
    /**
     * 
     * @type {BuildingLite}
     * @memberof Unit
     */
    building?: BuildingLite;
    /**
     * 
     * @type {Array<number>}
     * @memberof Unit
     */
    managerIds?: Array<number>;
    /**
     * 
     * @type {Array<Manager>}
     * @memberof Unit
     */
    managers?: Array<Manager>;
    /**
     * only for not connected unit
     * @type {boolean}
     * @memberof Unit
     */
    occupied?: boolean;
    /**
     * only for not connected unit
     * @type {string}
     * @memberof Unit
     */
    contactName?: string;
    /**
     * only for not connected unit
     * @type {string}
     * @memberof Unit
     */
    contactEmail?: string;
}
/**
 * 
 * @export
 * @interface UnitLite
 */
export interface UnitLite {
    /**
     * 
     * @type {number}
     * @memberof UnitLite
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitLite
     */
    name?: string;
    /**
     * address of the precinct
     * @type {string}
     * @memberof UnitLite
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitLite
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof UnitLite
     */
    precinctId?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitLite
     */
    buildingId?: number;
}
/**
 * 
 * @export
 * @interface WholesalePlan
 */
export interface WholesalePlan extends WholesalePlanLite {
    /**
     * 
     * @type {number}
     * @memberof WholesalePlan
     */
    precinctId?: number;
    /**
     * 
     * @type {PrecinctLite}
     * @memberof WholesalePlan
     */
    precinct?: PrecinctLite;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WholesalePlanContractTerm {
    Monthly = <any> 'monthly',
    Weekly = <any> 'weekly',
    Yearly = <any> 'yearly',
    Daily = <any> 'daily'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WholesalePlanInvoiceFrequency {
    Monthly = <any> 'monthly',
    Weekly = <any> 'weekly',
    Yearly = <any> 'yearly',
    Daily = <any> 'daily'
}
/**
 * wholesale plan
 * @export
 * @interface WholesalePlanLite
 */
export interface WholesalePlanLite {
    /**
     * 
     * @type {number}
     * @memberof WholesalePlanLite
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WholesalePlanLite
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WholesalePlanLite
     */
    description?: string;
    /**
     * 
     * @type {WholesalePlanContractTerm}
     * @memberof WholesalePlanLite
     */
    contractTerm?: WholesalePlanContractTerm;
    /**
     * 
     * @type {WholesalePlanInvoiceFrequency}
     * @memberof WholesalePlanLite
     */
    invoiceFrequency?: WholesalePlanInvoiceFrequency;
    /**
     * 
     * @type {number}
     * @memberof WholesalePlanLite
     */
    standardPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof WholesalePlanLite
     */
    margin?: number;
}
/**
 * AccountApi - fetch parameter creator
 * @export
 */
export const AccountApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * change password
         * @param {AuthChangePasswordBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountChangePasswordPost(body: AuthChangePasswordBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling accountChangePasswordPost.'};
            }
            const localVarPath = `/account/change-password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * reset password with token
         * @param {AuthChangePasswordWithTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountChangePasswordWithTokenPost(body: AuthChangePasswordWithTokenBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling accountChangePasswordWithTokenPost.'};
            }
            const localVarPath = `/account/change-password-with-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get account information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGet(options: any = {}): FetchArgs {
            const localVarPath = `/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * reset password
         * @param {AuthResetPassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPasswordPost(body: AuthResetPassword, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling accountResetPasswordPost.'};
            }
            const localVarPath = `/account/reset-password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body2" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * change password
         * @param {AuthChangePasswordBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountChangePasswordPost(body: AuthChangePasswordBody, options?: any): (basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountChangePasswordPost(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * reset password with token
         * @param {AuthChangePasswordWithTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountChangePasswordWithTokenPost(body: AuthChangePasswordWithTokenBody, options?: any): (basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountChangePasswordWithTokenPost(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get account information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGet(options?: any): (basePath?: string) => Promise<OperatorUser> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountGet(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * reset password
         * @param {AuthResetPassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPasswordPost(body: AuthResetPassword, options?: any): (basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountResetPasswordPost(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * change password
         * @param {AuthChangePasswordBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountChangePasswordPost(body: AuthChangePasswordBody, options?: any) {
            return AccountApiFp(configuration).accountChangePasswordPost(body, options)(basePath);
        },
        /**
         * reset password with token
         * @param {AuthChangePasswordWithTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountChangePasswordWithTokenPost(body: AuthChangePasswordWithTokenBody, options?: any) {
            return AccountApiFp(configuration).accountChangePasswordWithTokenPost(body, options)(basePath);
        },
        /**
         * get account information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGet(options?: any) {
            return AccountApiFp(configuration).accountGet(options)(basePath);
        },
        /**
         * reset password
         * @param {AuthResetPassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPasswordPost(body: AuthResetPassword, options?: any) {
            return AccountApiFp(configuration).accountResetPasswordPost(body, options)(basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * change password
     * @param {AuthChangePasswordBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountChangePasswordPost(body: AuthChangePasswordBody, options?: any) {
        return AccountApiFp(this.configuration).accountChangePasswordPost(body, options)(this.basePath);
    }

    /**
     * reset password with token
     * @param {AuthChangePasswordWithTokenBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountChangePasswordWithTokenPost(body: AuthChangePasswordWithTokenBody, options?: any) {
        return AccountApiFp(this.configuration).accountChangePasswordWithTokenPost(body, options)(this.basePath);
    }

    /**
     * get account information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGet(options?: any) {
        return AccountApiFp(this.configuration).accountGet(options)(this.basePath);
    }

    /**
     * reset password
     * @param {AuthResetPassword} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountResetPasswordPost(body: AuthResetPassword, options?: any) {
        return AccountApiFp(this.configuration).accountResetPasswordPost(body, options)(this.basePath);
    }

}
/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * login to system
         * @param {AuthLoginBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginPost(body: AuthLoginBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling authLoginPost.'};
            }
            const localVarPath = `/auth/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogoutPost(options: any = {}): FetchArgs {
            const localVarPath = `/auth/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get login user information and right
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authMePost(options: any = {}): FetchArgs {
            const localVarPath = `/auth/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * refresh token before expiration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshPost(options: any = {}): FetchArgs {
            const localVarPath = `/auth/refresh`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * login to system
         * @param {AuthLoginBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginPost(body: AuthLoginBody, options?: any): (basePath?: string) => Promise<BearerToken> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authLoginPost(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogoutPost(options?: any): (basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authLogoutPost(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get login user information and right
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authMePost(options?: any): (basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authMePost(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * refresh token before expiration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshPost(options?: any): (basePath?: string) => Promise<BearerToken> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authRefreshPost(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * login to system
         * @param {AuthLoginBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginPost(body: AuthLoginBody, options?: any) {
            return AuthApiFp(configuration).authLoginPost(body, options)(basePath);
        },
        /**
         * logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogoutPost(options?: any) {
            return AuthApiFp(configuration).authLogoutPost(options)(basePath);
        },
        /**
         * get login user information and right
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authMePost(options?: any) {
            return AuthApiFp(configuration).authMePost(options)(basePath);
        },
        /**
         * refresh token before expiration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshPost(options?: any) {
            return AuthApiFp(configuration).authRefreshPost(options)(basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * login to system
     * @param {AuthLoginBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLoginPost(body: AuthLoginBody, options?: any) {
        return AuthApiFp(this.configuration).authLoginPost(body, options)(this.basePath);
    }

    /**
     * logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogoutPost(options?: any) {
        return AuthApiFp(this.configuration).authLogoutPost(options)(this.basePath);
    }

    /**
     * get login user information and right
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authMePost(options?: any) {
        return AuthApiFp(this.configuration).authMePost(options)(this.basePath);
    }

    /**
     * refresh token before expiration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRefreshPost(options?: any) {
        return AuthApiFp(this.configuration).authRefreshPost(options)(this.basePath);
    }

}
/**
 * BuildingApi - fetch parameter creator
 * @export
 */
export const BuildingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get buildings
         * @param {number} [precinctId] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorBuildingsGet(precinctId?: number, search?: string, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/buildings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (precinctId !== undefined) {
                localVarQueryParameter['precinct_id'] = precinctId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get building with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorBuildingsIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorBuildingsIdGet.'};
            }
            const localVarPath = `/operator/buildings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update building information and managers
         * @param {Building} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorBuildingsIdPut(body: Building, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorBuildingsIdPut.'};
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorBuildingsIdPut.'};
            }
            const localVarPath = `/operator/buildings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Building" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingApi - functional programming interface
 * @export
 */
export const BuildingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get buildings
         * @param {number} [precinctId] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorBuildingsGet(precinctId?: number, search?: string, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<Building>> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).operatorBuildingsGet(precinctId, search, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get building with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorBuildingsIdGet(id: number, options?: any): (basePath?: string) => Promise<Building> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).operatorBuildingsIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * update building information and managers
         * @param {Building} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorBuildingsIdPut(body: Building, id: number, options?: any): (basePath?: string) => Promise<Building> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).operatorBuildingsIdPut(body, id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * BuildingApi - factory interface
 * @export
 */
export const BuildingApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get buildings
         * @param {number} [precinctId] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorBuildingsGet(precinctId?: number, search?: string, limit?: number, offset?: number, options?: any) {
            return BuildingApiFp(configuration).operatorBuildingsGet(precinctId, search, limit, offset, options)(basePath);
        },
        /**
         * get building with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorBuildingsIdGet(id: number, options?: any) {
            return BuildingApiFp(configuration).operatorBuildingsIdGet(id, options)(basePath);
        },
        /**
         * update building information and managers
         * @param {Building} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorBuildingsIdPut(body: Building, id: number, options?: any) {
            return BuildingApiFp(configuration).operatorBuildingsIdPut(body, id, options)(basePath);
        },
    };
};

/**
 * BuildingApi - object-oriented interface
 * @export
 * @class BuildingApi
 * @extends {BaseAPI}
 */
export class BuildingApi extends BaseAPI {
    /**
     * get buildings
     * @param {number} [precinctId] 
     * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public operatorBuildingsGet(precinctId?: number, search?: string, limit?: number, offset?: number, options?: any) {
        return BuildingApiFp(this.configuration).operatorBuildingsGet(precinctId, search, limit, offset, options)(this.basePath);
    }

    /**
     * get building with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public operatorBuildingsIdGet(id: number, options?: any) {
        return BuildingApiFp(this.configuration).operatorBuildingsIdGet(id, options)(this.basePath);
    }

    /**
     * update building information and managers
     * @param {Building} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public operatorBuildingsIdPut(body: Building, id: number, options?: any) {
        return BuildingApiFp(this.configuration).operatorBuildingsIdPut(body, id, options)(this.basePath);
    }

}
/**
 * CustomerApi - fetch parameter creator
 * @export
 */
export const CustomerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get customers
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorCustomersGet(search?: string, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/customers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get customer with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorCustomersIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorCustomersIdGet.'};
            }
            const localVarPath = `/operator/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get customers
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorCustomersGet(search?: string, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<Customer>> {
            const localVarFetchArgs = CustomerApiFetchParamCreator(configuration).operatorCustomersGet(search, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get customer with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorCustomersIdGet(id: number, options?: any): (basePath?: string) => Promise<Unit> {
            const localVarFetchArgs = CustomerApiFetchParamCreator(configuration).operatorCustomersIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get customers
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorCustomersGet(search?: string, limit?: number, offset?: number, options?: any) {
            return CustomerApiFp(configuration).operatorCustomersGet(search, limit, offset, options)(basePath);
        },
        /**
         * get customer with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorCustomersIdGet(id: number, options?: any) {
            return CustomerApiFp(configuration).operatorCustomersIdGet(id, options)(basePath);
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * get customers
     * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public operatorCustomersGet(search?: string, limit?: number, offset?: number, options?: any) {
        return CustomerApiFp(this.configuration).operatorCustomersGet(search, limit, offset, options)(this.basePath);
    }

    /**
     * get customer with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public operatorCustomersIdGet(id: number, options?: any) {
        return CustomerApiFp(this.configuration).operatorCustomersIdGet(id, options)(this.basePath);
    }

}
/**
 * FaultApi - fetch parameter creator
 * @export
 */
export const FaultApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get faults
         * @param {FaultStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorFaultsGet(status?: FaultStatus, search?: string, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/faults`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get fault with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorFaultsIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorFaultsIdGet.'};
            }
            const localVarPath = `/operator/faults/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * raise fault ticket
         * @param {Fault} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorFaultsPost(body: Fault, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorFaultsPost.'};
            }
            const localVarPath = `/operator/faults`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Fault" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FaultApi - functional programming interface
 * @export
 */
export const FaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get faults
         * @param {FaultStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorFaultsGet(status?: FaultStatus, search?: string, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<Fault>> {
            const localVarFetchArgs = FaultApiFetchParamCreator(configuration).operatorFaultsGet(status, search, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get fault with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorFaultsIdGet(id: number, options?: any): (basePath?: string) => Promise<Fault> {
            const localVarFetchArgs = FaultApiFetchParamCreator(configuration).operatorFaultsIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * raise fault ticket
         * @param {Fault} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorFaultsPost(body: Fault, options?: any): (basePath?: string) => Promise<Fault> {
            const localVarFetchArgs = FaultApiFetchParamCreator(configuration).operatorFaultsPost(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * FaultApi - factory interface
 * @export
 */
export const FaultApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get faults
         * @param {FaultStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorFaultsGet(status?: FaultStatus, search?: string, limit?: number, offset?: number, options?: any) {
            return FaultApiFp(configuration).operatorFaultsGet(status, search, limit, offset, options)(basePath);
        },
        /**
         * get fault with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorFaultsIdGet(id: number, options?: any) {
            return FaultApiFp(configuration).operatorFaultsIdGet(id, options)(basePath);
        },
        /**
         * raise fault ticket
         * @param {Fault} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorFaultsPost(body: Fault, options?: any) {
            return FaultApiFp(configuration).operatorFaultsPost(body, options)(basePath);
        },
    };
};

/**
 * FaultApi - object-oriented interface
 * @export
 * @class FaultApi
 * @extends {BaseAPI}
 */
export class FaultApi extends BaseAPI {
    /**
     * get faults
     * @param {FaultStatus} [status] 
     * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaultApi
     */
    public operatorFaultsGet(status?: FaultStatus, search?: string, limit?: number, offset?: number, options?: any) {
        return FaultApiFp(this.configuration).operatorFaultsGet(status, search, limit, offset, options)(this.basePath);
    }

    /**
     * get fault with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaultApi
     */
    public operatorFaultsIdGet(id: number, options?: any) {
        return FaultApiFp(this.configuration).operatorFaultsIdGet(id, options)(this.basePath);
    }

    /**
     * raise fault ticket
     * @param {Fault} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaultApi
     */
    public operatorFaultsPost(body: Fault, options?: any) {
        return FaultApiFp(this.configuration).operatorFaultsPost(body, options)(this.basePath);
    }

}
/**
 * NotificationApi - fetch parameter creator
 * @export
 */
export const NotificationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get notifications
         * @param {boolean} [read] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorNotificationsGet(read?: boolean, search?: string, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/notifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (read !== undefined) {
                localVarQueryParameter['read'] = read;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get notification with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorNotificationsIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorNotificationsIdGet.'};
            }
            const localVarPath = `/operator/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * mark notification as read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorNotificationsIdReadPost(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorNotificationsIdReadPost.'};
            }
            const localVarPath = `/operator/notifications/{id}/read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get notifications
         * @param {boolean} [read] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorNotificationsGet(read?: boolean, search?: string, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<Notification>> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).operatorNotificationsGet(read, search, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get notification with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorNotificationsIdGet(id: number, options?: any): (basePath?: string) => Promise<Notification> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).operatorNotificationsIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * mark notification as read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorNotificationsIdReadPost(id: number, options?: any): (basePath?: string) => Promise<Notification> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).operatorNotificationsIdReadPost(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get notifications
         * @param {boolean} [read] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorNotificationsGet(read?: boolean, search?: string, limit?: number, offset?: number, options?: any) {
            return NotificationApiFp(configuration).operatorNotificationsGet(read, search, limit, offset, options)(basePath);
        },
        /**
         * get notification with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorNotificationsIdGet(id: number, options?: any) {
            return NotificationApiFp(configuration).operatorNotificationsIdGet(id, options)(basePath);
        },
        /**
         * mark notification as read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorNotificationsIdReadPost(id: number, options?: any) {
            return NotificationApiFp(configuration).operatorNotificationsIdReadPost(id, options)(basePath);
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * get notifications
     * @param {boolean} [read] 
     * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public operatorNotificationsGet(read?: boolean, search?: string, limit?: number, offset?: number, options?: any) {
        return NotificationApiFp(this.configuration).operatorNotificationsGet(read, search, limit, offset, options)(this.basePath);
    }

    /**
     * get notification with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public operatorNotificationsIdGet(id: number, options?: any) {
        return NotificationApiFp(this.configuration).operatorNotificationsIdGet(id, options)(this.basePath);
    }

    /**
     * mark notification as read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public operatorNotificationsIdReadPost(id: number, options?: any) {
        return NotificationApiFp(this.configuration).operatorNotificationsIdReadPost(id, options)(this.basePath);
    }

}
/**
 * OperatorInvoiceApi - fetch parameter creator
 * @export
 */
export const OperatorInvoiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get invoices
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorInvoicesGet(limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/invoices`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get invoice with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorInvoicesIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorInvoicesIdGet.'};
            }
            const localVarPath = `/operator/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperatorInvoiceApi - functional programming interface
 * @export
 */
export const OperatorInvoiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get invoices
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorInvoicesGet(limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<OperatorInvoice>> {
            const localVarFetchArgs = OperatorInvoiceApiFetchParamCreator(configuration).operatorInvoicesGet(limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get invoice with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorInvoicesIdGet(id: number, options?: any): (basePath?: string) => Promise<OperatorInvoice> {
            const localVarFetchArgs = OperatorInvoiceApiFetchParamCreator(configuration).operatorInvoicesIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * OperatorInvoiceApi - factory interface
 * @export
 */
export const OperatorInvoiceApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get invoices
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorInvoicesGet(limit?: number, offset?: number, options?: any) {
            return OperatorInvoiceApiFp(configuration).operatorInvoicesGet(limit, offset, options)(basePath);
        },
        /**
         * get invoice with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorInvoicesIdGet(id: number, options?: any) {
            return OperatorInvoiceApiFp(configuration).operatorInvoicesIdGet(id, options)(basePath);
        },
    };
};

/**
 * OperatorInvoiceApi - object-oriented interface
 * @export
 * @class OperatorInvoiceApi
 * @extends {BaseAPI}
 */
export class OperatorInvoiceApi extends BaseAPI {
    /**
     * get invoices
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorInvoiceApi
     */
    public operatorInvoicesGet(limit?: number, offset?: number, options?: any) {
        return OperatorInvoiceApiFp(this.configuration).operatorInvoicesGet(limit, offset, options)(this.basePath);
    }

    /**
     * get invoice with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorInvoiceApi
     */
    public operatorInvoicesIdGet(id: number, options?: any) {
        return OperatorInvoiceApiFp(this.configuration).operatorInvoicesIdGet(id, options)(this.basePath);
    }

}
/**
 * OperatorUserApi - fetch parameter creator
 * @export
 */
export const OperatorUserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get operator_users
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorOperatorUsersGet(search?: string, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/operator_users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get operator user with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorOperatorUsersIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorOperatorUsersIdGet.'};
            }
            const localVarPath = `/operator/operator_users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperatorUserApi - functional programming interface
 * @export
 */
export const OperatorUserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get operator_users
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorOperatorUsersGet(search?: string, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<OperatorUser>> {
            const localVarFetchArgs = OperatorUserApiFetchParamCreator(configuration).operatorOperatorUsersGet(search, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get operator user with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorOperatorUsersIdGet(id: number, options?: any): (basePath?: string) => Promise<OperatorUser> {
            const localVarFetchArgs = OperatorUserApiFetchParamCreator(configuration).operatorOperatorUsersIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * OperatorUserApi - factory interface
 * @export
 */
export const OperatorUserApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get operator_users
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorOperatorUsersGet(search?: string, limit?: number, offset?: number, options?: any) {
            return OperatorUserApiFp(configuration).operatorOperatorUsersGet(search, limit, offset, options)(basePath);
        },
        /**
         * get operator user with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorOperatorUsersIdGet(id: number, options?: any) {
            return OperatorUserApiFp(configuration).operatorOperatorUsersIdGet(id, options)(basePath);
        },
    };
};

/**
 * OperatorUserApi - object-oriented interface
 * @export
 * @class OperatorUserApi
 * @extends {BaseAPI}
 */
export class OperatorUserApi extends BaseAPI {
    /**
     * get operator_users
     * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorUserApi
     */
    public operatorOperatorUsersGet(search?: string, limit?: number, offset?: number, options?: any) {
        return OperatorUserApiFp(this.configuration).operatorOperatorUsersGet(search, limit, offset, options)(this.basePath);
    }

    /**
     * get operator user with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorUserApi
     */
    public operatorOperatorUsersIdGet(id: number, options?: any) {
        return OperatorUserApiFp(this.configuration).operatorOperatorUsersIdGet(id, options)(this.basePath);
    }

}
/**
 * PaymentMethodApi - fetch parameter creator
 * @export
 */
export const PaymentMethodApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get payment methods
         * @param {number} [customerId] 
         * @param {number} [retailServiceId] 
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPaymentMethodsGet(customerId?: number, retailServiceId?: number, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/payment_methods`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customer_id'] = customerId;
            }

            if (retailServiceId !== undefined) {
                localVarQueryParameter['retail_service_id'] = retailServiceId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get payment methods
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPaymentMethodsIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorPaymentMethodsIdGet.'};
            }
            const localVarPath = `/operator/payment_methods/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create new payment method
         * @param {PaymentMethod} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPaymentMethodsPost(body: PaymentMethod, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorPaymentMethodsPost.'};
            }
            const localVarPath = `/operator/payment_methods`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaymentMethod" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentMethodApi - functional programming interface
 * @export
 */
export const PaymentMethodApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get payment methods
         * @param {number} [customerId] 
         * @param {number} [retailServiceId] 
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPaymentMethodsGet(customerId?: number, retailServiceId?: number, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<PaymentMethod>> {
            const localVarFetchArgs = PaymentMethodApiFetchParamCreator(configuration).operatorPaymentMethodsGet(customerId, retailServiceId, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get payment methods
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPaymentMethodsIdGet(id: number, options?: any): (basePath?: string) => Promise<Array<PaymentMethod>> {
            const localVarFetchArgs = PaymentMethodApiFetchParamCreator(configuration).operatorPaymentMethodsIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * create new payment method
         * @param {PaymentMethod} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPaymentMethodsPost(body: PaymentMethod, options?: any): (basePath?: string) => Promise<PaymentMethod> {
            const localVarFetchArgs = PaymentMethodApiFetchParamCreator(configuration).operatorPaymentMethodsPost(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * PaymentMethodApi - factory interface
 * @export
 */
export const PaymentMethodApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get payment methods
         * @param {number} [customerId] 
         * @param {number} [retailServiceId] 
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPaymentMethodsGet(customerId?: number, retailServiceId?: number, limit?: number, offset?: number, options?: any) {
            return PaymentMethodApiFp(configuration).operatorPaymentMethodsGet(customerId, retailServiceId, limit, offset, options)(basePath);
        },
        /**
         * get payment methods
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPaymentMethodsIdGet(id: number, options?: any) {
            return PaymentMethodApiFp(configuration).operatorPaymentMethodsIdGet(id, options)(basePath);
        },
        /**
         * create new payment method
         * @param {PaymentMethod} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPaymentMethodsPost(body: PaymentMethod, options?: any) {
            return PaymentMethodApiFp(configuration).operatorPaymentMethodsPost(body, options)(basePath);
        },
    };
};

/**
 * PaymentMethodApi - object-oriented interface
 * @export
 * @class PaymentMethodApi
 * @extends {BaseAPI}
 */
export class PaymentMethodApi extends BaseAPI {
    /**
     * get payment methods
     * @param {number} [customerId] 
     * @param {number} [retailServiceId] 
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodApi
     */
    public operatorPaymentMethodsGet(customerId?: number, retailServiceId?: number, limit?: number, offset?: number, options?: any) {
        return PaymentMethodApiFp(this.configuration).operatorPaymentMethodsGet(customerId, retailServiceId, limit, offset, options)(this.basePath);
    }

    /**
     * get payment methods
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodApi
     */
    public operatorPaymentMethodsIdGet(id: number, options?: any) {
        return PaymentMethodApiFp(this.configuration).operatorPaymentMethodsIdGet(id, options)(this.basePath);
    }

    /**
     * create new payment method
     * @param {PaymentMethod} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodApi
     */
    public operatorPaymentMethodsPost(body: PaymentMethod, options?: any) {
        return PaymentMethodApiFp(this.configuration).operatorPaymentMethodsPost(body, options)(this.basePath);
    }

}
/**
 * PrecinctApi - fetch parameter creator
 * @export
 */
export const PrecinctApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get precincts
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPrecinctsGet(search?: string, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/precincts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get Precinct with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPrecinctsIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorPrecinctsIdGet.'};
            }
            const localVarPath = `/operator/precincts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update precinct's information and managers
         * @param {Precinct} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPrecinctsIdPut(body: Precinct, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorPrecinctsIdPut.'};
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorPrecinctsIdPut.'};
            }
            const localVarPath = `/operator/precincts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Precinct" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrecinctApi - functional programming interface
 * @export
 */
export const PrecinctApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get precincts
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPrecinctsGet(search?: string, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<Precinct>> {
            const localVarFetchArgs = PrecinctApiFetchParamCreator(configuration).operatorPrecinctsGet(search, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get Precinct with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPrecinctsIdGet(id: number, options?: any): (basePath?: string) => Promise<Precinct> {
            const localVarFetchArgs = PrecinctApiFetchParamCreator(configuration).operatorPrecinctsIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * update precinct's information and managers
         * @param {Precinct} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPrecinctsIdPut(body: Precinct, id: number, options?: any): (basePath?: string) => Promise<Precinct> {
            const localVarFetchArgs = PrecinctApiFetchParamCreator(configuration).operatorPrecinctsIdPut(body, id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * PrecinctApi - factory interface
 * @export
 */
export const PrecinctApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get precincts
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPrecinctsGet(search?: string, limit?: number, offset?: number, options?: any) {
            return PrecinctApiFp(configuration).operatorPrecinctsGet(search, limit, offset, options)(basePath);
        },
        /**
         * get Precinct with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPrecinctsIdGet(id: number, options?: any) {
            return PrecinctApiFp(configuration).operatorPrecinctsIdGet(id, options)(basePath);
        },
        /**
         * update precinct's information and managers
         * @param {Precinct} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorPrecinctsIdPut(body: Precinct, id: number, options?: any) {
            return PrecinctApiFp(configuration).operatorPrecinctsIdPut(body, id, options)(basePath);
        },
    };
};

/**
 * PrecinctApi - object-oriented interface
 * @export
 * @class PrecinctApi
 * @extends {BaseAPI}
 */
export class PrecinctApi extends BaseAPI {
    /**
     * get precincts
     * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrecinctApi
     */
    public operatorPrecinctsGet(search?: string, limit?: number, offset?: number, options?: any) {
        return PrecinctApiFp(this.configuration).operatorPrecinctsGet(search, limit, offset, options)(this.basePath);
    }

    /**
     * get Precinct with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrecinctApi
     */
    public operatorPrecinctsIdGet(id: number, options?: any) {
        return PrecinctApiFp(this.configuration).operatorPrecinctsIdGet(id, options)(this.basePath);
    }

    /**
     * update precinct's information and managers
     * @param {Precinct} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrecinctApi
     */
    public operatorPrecinctsIdPut(body: Precinct, id: number, options?: any) {
        return PrecinctApiFp(this.configuration).operatorPrecinctsIdPut(body, id, options)(this.basePath);
    }

}
/**
 * ReportApi - fetch parameter creator
 * @export
 */
export const ReportApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get reports
         * @param {number} [period] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorReportsGet(period?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/reports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get reports
         * @param {number} [period] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorReportsGet(period?: number, options?: any): (basePath?: string) => Promise<Report> {
            const localVarFetchArgs = ReportApiFetchParamCreator(configuration).operatorReportsGet(period, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get reports
         * @param {number} [period] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorReportsGet(period?: number, options?: any) {
            return ReportApiFp(configuration).operatorReportsGet(period, options)(basePath);
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * get reports
     * @param {number} [period] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public operatorReportsGet(period?: number, options?: any) {
        return ReportApiFp(this.configuration).operatorReportsGet(period, options)(this.basePath);
    }

}
/**
 * RetailOrderApi - fetch parameter creator
 * @export
 */
export const RetailOrderApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get retail_orders
         * @param {number} [precinctId] 
         * @param {number} [buildingId] 
         * @param {number} [unitId] 
         * @param {boolean} [openOnly] 
         * @param {RetailOrderStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersGet(precinctId?: number, buildingId?: number, unitId?: number, openOnly?: boolean, status?: RetailOrderStatus, search?: string, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/retail_orders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (precinctId !== undefined) {
                localVarQueryParameter['precinct_id'] = precinctId;
            }

            if (buildingId !== undefined) {
                localVarQueryParameter['building_id'] = buildingId;
            }

            if (unitId !== undefined) {
                localVarQueryParameter['unit_id'] = unitId;
            }

            if (openOnly !== undefined) {
                localVarQueryParameter['open_only'] = openOnly;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * cancel retail order
         * @param {RetailOrderCancelBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersIdCancelPost(body: RetailOrderCancelBody, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorRetailOrdersIdCancelPost.'};
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorRetailOrdersIdCancelPost.'};
            }
            const localVarPath = `/operator/retail_orders/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body4" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete one retail order
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersIdDelete(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorRetailOrdersIdDelete.'};
            }
            const localVarPath = `/operator/retail_orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get retail order with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorRetailOrdersIdGet.'};
            }
            const localVarPath = `/operator/retail_orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * place retail order with complex data
         * @param {RetailOrderNew} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersNewPost(body: RetailOrderNew, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorRetailOrdersNewPost.'};
            }
            const localVarPath = `/operator/retail_orders/new`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RetailOrderNew" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * place retail order
         * @param {RetailOrder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersPost(body: RetailOrder, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorRetailOrdersPost.'};
            }
            const localVarPath = `/operator/retail_orders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RetailOrder" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RetailOrderApi - functional programming interface
 * @export
 */
export const RetailOrderApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get retail_orders
         * @param {number} [precinctId] 
         * @param {number} [buildingId] 
         * @param {number} [unitId] 
         * @param {boolean} [openOnly] 
         * @param {RetailOrderStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersGet(precinctId?: number, buildingId?: number, unitId?: number, openOnly?: boolean, status?: RetailOrderStatus, search?: string, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<RetailOrder>> {
            const localVarFetchArgs = RetailOrderApiFetchParamCreator(configuration).operatorRetailOrdersGet(precinctId, buildingId, unitId, openOnly, status, search, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * cancel retail order
         * @param {RetailOrderCancelBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersIdCancelPost(body: RetailOrderCancelBody, id: number, options?: any): (basePath?: string) => Promise<RetailOrder> {
            const localVarFetchArgs = RetailOrderApiFetchParamCreator(configuration).operatorRetailOrdersIdCancelPost(body, id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * delete one retail order
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersIdDelete(id: number, options?: any): (basePath?: string) => Promise<Response> {
            const localVarFetchArgs = RetailOrderApiFetchParamCreator(configuration).operatorRetailOrdersIdDelete(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get retail order with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersIdGet(id: number, options?: any): (basePath?: string) => Promise<RetailOrder> {
            const localVarFetchArgs = RetailOrderApiFetchParamCreator(configuration).operatorRetailOrdersIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * place retail order with complex data
         * @param {RetailOrderNew} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersNewPost(body: RetailOrderNew, options?: any): (basePath?: string) => Promise<RetailOrder> {
            const localVarFetchArgs = RetailOrderApiFetchParamCreator(configuration).operatorRetailOrdersNewPost(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * place retail order
         * @param {RetailOrder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersPost(body: RetailOrder, options?: any): (basePath?: string) => Promise<RetailOrder> {
            const localVarFetchArgs = RetailOrderApiFetchParamCreator(configuration).operatorRetailOrdersPost(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * RetailOrderApi - factory interface
 * @export
 */
export const RetailOrderApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get retail_orders
         * @param {number} [precinctId] 
         * @param {number} [buildingId] 
         * @param {number} [unitId] 
         * @param {boolean} [openOnly] 
         * @param {RetailOrderStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersGet(precinctId?: number, buildingId?: number, unitId?: number, openOnly?: boolean, status?: RetailOrderStatus, search?: string, limit?: number, offset?: number, options?: any) {
            return RetailOrderApiFp(configuration).operatorRetailOrdersGet(precinctId, buildingId, unitId, openOnly, status, search, limit, offset, options)(basePath);
        },
        /**
         * cancel retail order
         * @param {RetailOrderCancelBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersIdCancelPost(body: RetailOrderCancelBody, id: number, options?: any) {
            return RetailOrderApiFp(configuration).operatorRetailOrdersIdCancelPost(body, id, options)(basePath);
        },
        /**
         * delete one retail order
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersIdDelete(id: number, options?: any) {
            return RetailOrderApiFp(configuration).operatorRetailOrdersIdDelete(id, options)(basePath);
        },
        /**
         * get retail order with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersIdGet(id: number, options?: any) {
            return RetailOrderApiFp(configuration).operatorRetailOrdersIdGet(id, options)(basePath);
        },
        /**
         * place retail order with complex data
         * @param {RetailOrderNew} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersNewPost(body: RetailOrderNew, options?: any) {
            return RetailOrderApiFp(configuration).operatorRetailOrdersNewPost(body, options)(basePath);
        },
        /**
         * place retail order
         * @param {RetailOrder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailOrdersPost(body: RetailOrder, options?: any) {
            return RetailOrderApiFp(configuration).operatorRetailOrdersPost(body, options)(basePath);
        },
    };
};

/**
 * RetailOrderApi - object-oriented interface
 * @export
 * @class RetailOrderApi
 * @extends {BaseAPI}
 */
export class RetailOrderApi extends BaseAPI {
    /**
     * get retail_orders
     * @param {number} [precinctId] 
     * @param {number} [buildingId] 
     * @param {number} [unitId] 
     * @param {boolean} [openOnly] 
     * @param {RetailOrderStatus} [status] 
     * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailOrderApi
     */
    public operatorRetailOrdersGet(precinctId?: number, buildingId?: number, unitId?: number, openOnly?: boolean, status?: RetailOrderStatus, search?: string, limit?: number, offset?: number, options?: any) {
        return RetailOrderApiFp(this.configuration).operatorRetailOrdersGet(precinctId, buildingId, unitId, openOnly, status, search, limit, offset, options)(this.basePath);
    }

    /**
     * cancel retail order
     * @param {RetailOrderCancelBody} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailOrderApi
     */
    public operatorRetailOrdersIdCancelPost(body: RetailOrderCancelBody, id: number, options?: any) {
        return RetailOrderApiFp(this.configuration).operatorRetailOrdersIdCancelPost(body, id, options)(this.basePath);
    }

    /**
     * delete one retail order
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailOrderApi
     */
    public operatorRetailOrdersIdDelete(id: number, options?: any) {
        return RetailOrderApiFp(this.configuration).operatorRetailOrdersIdDelete(id, options)(this.basePath);
    }

    /**
     * get retail order with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailOrderApi
     */
    public operatorRetailOrdersIdGet(id: number, options?: any) {
        return RetailOrderApiFp(this.configuration).operatorRetailOrdersIdGet(id, options)(this.basePath);
    }

    /**
     * place retail order with complex data
     * @param {RetailOrderNew} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailOrderApi
     */
    public operatorRetailOrdersNewPost(body: RetailOrderNew, options?: any) {
        return RetailOrderApiFp(this.configuration).operatorRetailOrdersNewPost(body, options)(this.basePath);
    }

    /**
     * place retail order
     * @param {RetailOrder} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailOrderApi
     */
    public operatorRetailOrdersPost(body: RetailOrder, options?: any) {
        return RetailOrderApiFp(this.configuration).operatorRetailOrdersPost(body, options)(this.basePath);
    }

}
/**
 * RetailPlanApi - fetch parameter creator
 * @export
 */
export const RetailPlanApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get retail_plans
         * @param {number} [precinctId] 
         * @param {number} [buildingId] 
         * @param {number} [unitId] 
         * @param {RetailPlanStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansGet(precinctId?: number, buildingId?: number, unitId?: number, status?: RetailPlanStatus, search?: string, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/retail_plans`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (precinctId !== undefined) {
                localVarQueryParameter['precinct_id'] = precinctId;
            }

            if (buildingId !== undefined) {
                localVarQueryParameter['building_id'] = buildingId;
            }

            if (unitId !== undefined) {
                localVarQueryParameter['unit_id'] = unitId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deactivate retail plan
         * @param {RetailPlanDeactivateBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdDeativatePost(body: RetailPlanDeactivateBody, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorRetailPlansIdDeativatePost.'};
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorRetailPlansIdDeativatePost.'};
            }
            const localVarPath = `/operator/retail_plans/{id}/deativate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body6" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete one retail plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdDelete(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorRetailPlansIdDelete.'};
            }
            const localVarPath = `/operator/retail_plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get wholesale_plan with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorRetailPlansIdGet.'};
            }
            const localVarPath = `/operator/retail_plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update retail plan
         * @param {RetailPlanUpdateBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdPut(body: RetailPlanUpdateBody, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorRetailPlansIdPut.'};
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorRetailPlansIdPut.'};
            }
            const localVarPath = `/operator/retail_plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body5" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create retail plan
         * @param {RetailPlan} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansPost(body: RetailPlan, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorRetailPlansPost.'};
            }
            const localVarPath = `/operator/retail_plans`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RetailPlan" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RetailPlanApi - functional programming interface
 * @export
 */
export const RetailPlanApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get retail_plans
         * @param {number} [precinctId] 
         * @param {number} [buildingId] 
         * @param {number} [unitId] 
         * @param {RetailPlanStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansGet(precinctId?: number, buildingId?: number, unitId?: number, status?: RetailPlanStatus, search?: string, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<RetailPlan>> {
            const localVarFetchArgs = RetailPlanApiFetchParamCreator(configuration).operatorRetailPlansGet(precinctId, buildingId, unitId, status, search, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * deactivate retail plan
         * @param {RetailPlanDeactivateBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdDeativatePost(body: RetailPlanDeactivateBody, id: number, options?: any): (basePath?: string) => Promise<RetailPlan> {
            const localVarFetchArgs = RetailPlanApiFetchParamCreator(configuration).operatorRetailPlansIdDeativatePost(body, id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * delete one retail plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdDelete(id: number, options?: any): (basePath?: string) => Promise<Response> {
            const localVarFetchArgs = RetailPlanApiFetchParamCreator(configuration).operatorRetailPlansIdDelete(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get wholesale_plan with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdGet(id: number, options?: any): (basePath?: string) => Promise<RetailPlan> {
            const localVarFetchArgs = RetailPlanApiFetchParamCreator(configuration).operatorRetailPlansIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * update retail plan
         * @param {RetailPlanUpdateBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdPut(body: RetailPlanUpdateBody, id: number, options?: any): (basePath?: string) => Promise<RetailPlan> {
            const localVarFetchArgs = RetailPlanApiFetchParamCreator(configuration).operatorRetailPlansIdPut(body, id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * create retail plan
         * @param {RetailPlan} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansPost(body: RetailPlan, options?: any): (basePath?: string) => Promise<RetailPlan> {
            const localVarFetchArgs = RetailPlanApiFetchParamCreator(configuration).operatorRetailPlansPost(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * RetailPlanApi - factory interface
 * @export
 */
export const RetailPlanApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get retail_plans
         * @param {number} [precinctId] 
         * @param {number} [buildingId] 
         * @param {number} [unitId] 
         * @param {RetailPlanStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansGet(precinctId?: number, buildingId?: number, unitId?: number, status?: RetailPlanStatus, search?: string, limit?: number, offset?: number, options?: any) {
            return RetailPlanApiFp(configuration).operatorRetailPlansGet(precinctId, buildingId, unitId, status, search, limit, offset, options)(basePath);
        },
        /**
         * deactivate retail plan
         * @param {RetailPlanDeactivateBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdDeativatePost(body: RetailPlanDeactivateBody, id: number, options?: any) {
            return RetailPlanApiFp(configuration).operatorRetailPlansIdDeativatePost(body, id, options)(basePath);
        },
        /**
         * delete one retail plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdDelete(id: number, options?: any) {
            return RetailPlanApiFp(configuration).operatorRetailPlansIdDelete(id, options)(basePath);
        },
        /**
         * get wholesale_plan with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdGet(id: number, options?: any) {
            return RetailPlanApiFp(configuration).operatorRetailPlansIdGet(id, options)(basePath);
        },
        /**
         * update retail plan
         * @param {RetailPlanUpdateBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansIdPut(body: RetailPlanUpdateBody, id: number, options?: any) {
            return RetailPlanApiFp(configuration).operatorRetailPlansIdPut(body, id, options)(basePath);
        },
        /**
         * create retail plan
         * @param {RetailPlan} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailPlansPost(body: RetailPlan, options?: any) {
            return RetailPlanApiFp(configuration).operatorRetailPlansPost(body, options)(basePath);
        },
    };
};

/**
 * RetailPlanApi - object-oriented interface
 * @export
 * @class RetailPlanApi
 * @extends {BaseAPI}
 */
export class RetailPlanApi extends BaseAPI {
    /**
     * get retail_plans
     * @param {number} [precinctId] 
     * @param {number} [buildingId] 
     * @param {number} [unitId] 
     * @param {RetailPlanStatus} [status] 
     * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailPlanApi
     */
    public operatorRetailPlansGet(precinctId?: number, buildingId?: number, unitId?: number, status?: RetailPlanStatus, search?: string, limit?: number, offset?: number, options?: any) {
        return RetailPlanApiFp(this.configuration).operatorRetailPlansGet(precinctId, buildingId, unitId, status, search, limit, offset, options)(this.basePath);
    }

    /**
     * deactivate retail plan
     * @param {RetailPlanDeactivateBody} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailPlanApi
     */
    public operatorRetailPlansIdDeativatePost(body: RetailPlanDeactivateBody, id: number, options?: any) {
        return RetailPlanApiFp(this.configuration).operatorRetailPlansIdDeativatePost(body, id, options)(this.basePath);
    }

    /**
     * delete one retail plan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailPlanApi
     */
    public operatorRetailPlansIdDelete(id: number, options?: any) {
        return RetailPlanApiFp(this.configuration).operatorRetailPlansIdDelete(id, options)(this.basePath);
    }

    /**
     * get wholesale_plan with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailPlanApi
     */
    public operatorRetailPlansIdGet(id: number, options?: any) {
        return RetailPlanApiFp(this.configuration).operatorRetailPlansIdGet(id, options)(this.basePath);
    }

    /**
     * update retail plan
     * @param {RetailPlanUpdateBody} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailPlanApi
     */
    public operatorRetailPlansIdPut(body: RetailPlanUpdateBody, id: number, options?: any) {
        return RetailPlanApiFp(this.configuration).operatorRetailPlansIdPut(body, id, options)(this.basePath);
    }

    /**
     * create retail plan
     * @param {RetailPlan} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailPlanApi
     */
    public operatorRetailPlansPost(body: RetailPlan, options?: any) {
        return RetailPlanApiFp(this.configuration).operatorRetailPlansPost(body, options)(this.basePath);
    }

}
/**
 * RetailServiceApi - fetch parameter creator
 * @export
 */
export const RetailServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get retail_services
         * @param {number} [precinctId] 
         * @param {number} [buildingId] 
         * @param {number} [unitId] 
         * @param {number} [customerId] 
         * @param {RetailServiceStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailServicesGet(precinctId?: number, buildingId?: number, unitId?: number, customerId?: number, status?: RetailServiceStatus, search?: string, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/retail_services`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (precinctId !== undefined) {
                localVarQueryParameter['precinct_id'] = precinctId;
            }

            if (buildingId !== undefined) {
                localVarQueryParameter['building_id'] = buildingId;
            }

            if (unitId !== undefined) {
                localVarQueryParameter['unit_id'] = unitId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customer_id'] = customerId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get retail service with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailServicesIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorRetailServicesIdGet.'};
            }
            const localVarPath = `/operator/retail_services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RetailServiceApi - functional programming interface
 * @export
 */
export const RetailServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get retail_services
         * @param {number} [precinctId] 
         * @param {number} [buildingId] 
         * @param {number} [unitId] 
         * @param {number} [customerId] 
         * @param {RetailServiceStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailServicesGet(precinctId?: number, buildingId?: number, unitId?: number, customerId?: number, status?: RetailServiceStatus, search?: string, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<RetailService>> {
            const localVarFetchArgs = RetailServiceApiFetchParamCreator(configuration).operatorRetailServicesGet(precinctId, buildingId, unitId, customerId, status, search, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get retail service with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailServicesIdGet(id: number, options?: any): (basePath?: string) => Promise<RetailService> {
            const localVarFetchArgs = RetailServiceApiFetchParamCreator(configuration).operatorRetailServicesIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * RetailServiceApi - factory interface
 * @export
 */
export const RetailServiceApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get retail_services
         * @param {number} [precinctId] 
         * @param {number} [buildingId] 
         * @param {number} [unitId] 
         * @param {number} [customerId] 
         * @param {RetailServiceStatus} [status] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailServicesGet(precinctId?: number, buildingId?: number, unitId?: number, customerId?: number, status?: RetailServiceStatus, search?: string, limit?: number, offset?: number, options?: any) {
            return RetailServiceApiFp(configuration).operatorRetailServicesGet(precinctId, buildingId, unitId, customerId, status, search, limit, offset, options)(basePath);
        },
        /**
         * get retail service with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorRetailServicesIdGet(id: number, options?: any) {
            return RetailServiceApiFp(configuration).operatorRetailServicesIdGet(id, options)(basePath);
        },
    };
};

/**
 * RetailServiceApi - object-oriented interface
 * @export
 * @class RetailServiceApi
 * @extends {BaseAPI}
 */
export class RetailServiceApi extends BaseAPI {
    /**
     * get retail_services
     * @param {number} [precinctId] 
     * @param {number} [buildingId] 
     * @param {number} [unitId] 
     * @param {number} [customerId] 
     * @param {RetailServiceStatus} [status] 
     * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailServiceApi
     */
    public operatorRetailServicesGet(precinctId?: number, buildingId?: number, unitId?: number, customerId?: number, status?: RetailServiceStatus, search?: string, limit?: number, offset?: number, options?: any) {
        return RetailServiceApiFp(this.configuration).operatorRetailServicesGet(precinctId, buildingId, unitId, customerId, status, search, limit, offset, options)(this.basePath);
    }

    /**
     * get retail service with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailServiceApi
     */
    public operatorRetailServicesIdGet(id: number, options?: any) {
        return RetailServiceApiFp(this.configuration).operatorRetailServicesIdGet(id, options)(this.basePath);
    }

}
/**
 * UnitApi - fetch parameter creator
 * @export
 */
export const UnitApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get units
         * @param {number} [precinctId] 
         * @param {number} [buildId] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsGet(precinctId?: number, buildId?: number, search?: string, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/units`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (precinctId !== undefined) {
                localVarQueryParameter['precinct_id'] = precinctId;
            }

            if (buildId !== undefined) {
                localVarQueryParameter['build_id'] = buildId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update unit's contact
         * @param {UnitUpdateBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsIdContactPost(body: UnitUpdateBody, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorUnitsIdContactPost.'};
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorUnitsIdContactPost.'};
            }
            const localVarPath = `/operator/units/{id}/contact`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body7" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get units with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorUnitsIdGet.'};
            }
            const localVarPath = `/operator/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update unit's information and managers
         * @param {Unit} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsIdPut(body: Unit, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw {'body': 'Required parameter body was null or undefined when calling operatorUnitsIdPut.'};
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorUnitsIdPut.'};
            }
            const localVarPath = `/operator/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Unit" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitApi - functional programming interface
 * @export
 */
export const UnitApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get units
         * @param {number} [precinctId] 
         * @param {number} [buildId] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsGet(precinctId?: number, buildId?: number, search?: string, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<Unit>> {
            const localVarFetchArgs = UnitApiFetchParamCreator(configuration).operatorUnitsGet(precinctId, buildId, search, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * update unit's contact
         * @param {UnitUpdateBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsIdContactPost(body: UnitUpdateBody, id: number, options?: any): (basePath?: string) => Promise<Unit> {
            const localVarFetchArgs = UnitApiFetchParamCreator(configuration).operatorUnitsIdContactPost(body, id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get units with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsIdGet(id: number, options?: any): (basePath?: string) => Promise<Unit> {
            const localVarFetchArgs = UnitApiFetchParamCreator(configuration).operatorUnitsIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * update unit's information and managers
         * @param {Unit} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsIdPut(body: Unit, id: number, options?: any): (basePath?: string) => Promise<Unit> {
            const localVarFetchArgs = UnitApiFetchParamCreator(configuration).operatorUnitsIdPut(body, id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * UnitApi - factory interface
 * @export
 */
export const UnitApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get units
         * @param {number} [precinctId] 
         * @param {number} [buildId] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsGet(precinctId?: number, buildId?: number, search?: string, limit?: number, offset?: number, options?: any) {
            return UnitApiFp(configuration).operatorUnitsGet(precinctId, buildId, search, limit, offset, options)(basePath);
        },
        /**
         * update unit's contact
         * @param {UnitUpdateBody} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsIdContactPost(body: UnitUpdateBody, id: number, options?: any) {
            return UnitApiFp(configuration).operatorUnitsIdContactPost(body, id, options)(basePath);
        },
        /**
         * get units with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsIdGet(id: number, options?: any) {
            return UnitApiFp(configuration).operatorUnitsIdGet(id, options)(basePath);
        },
        /**
         * update unit's information and managers
         * @param {Unit} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorUnitsIdPut(body: Unit, id: number, options?: any) {
            return UnitApiFp(configuration).operatorUnitsIdPut(body, id, options)(basePath);
        },
    };
};

/**
 * UnitApi - object-oriented interface
 * @export
 * @class UnitApi
 * @extends {BaseAPI}
 */
export class UnitApi extends BaseAPI {
    /**
     * get units
     * @param {number} [precinctId] 
     * @param {number} [buildId] 
     * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public operatorUnitsGet(precinctId?: number, buildId?: number, search?: string, limit?: number, offset?: number, options?: any) {
        return UnitApiFp(this.configuration).operatorUnitsGet(precinctId, buildId, search, limit, offset, options)(this.basePath);
    }

    /**
     * update unit's contact
     * @param {UnitUpdateBody} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public operatorUnitsIdContactPost(body: UnitUpdateBody, id: number, options?: any) {
        return UnitApiFp(this.configuration).operatorUnitsIdContactPost(body, id, options)(this.basePath);
    }

    /**
     * get units with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public operatorUnitsIdGet(id: number, options?: any) {
        return UnitApiFp(this.configuration).operatorUnitsIdGet(id, options)(this.basePath);
    }

    /**
     * update unit's information and managers
     * @param {Unit} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public operatorUnitsIdPut(body: Unit, id: number, options?: any) {
        return UnitApiFp(this.configuration).operatorUnitsIdPut(body, id, options)(this.basePath);
    }

}
/**
 * WholesalePlanApi - fetch parameter creator
 * @export
 */
export const WholesalePlanApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get wholesale_plans
         * @param {number} [precinctId] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorWholesalePlansGet(precinctId?: number, search?: string, limit?: number, offset?: number, options: any = {}): FetchArgs {
            const localVarPath = `/operator/wholesale_plans`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (precinctId !== undefined) {
                localVarQueryParameter['precinct_id'] = precinctId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get wholesale_plan with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorWholesalePlansIdGet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw {'id': 'Required parameter id was null or undefined when calling operatorWholesalePlansIdGet.'};
            }
            const localVarPath = `/operator/wholesale_plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WholesalePlanApi - functional programming interface
 * @export
 */
export const WholesalePlanApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get wholesale_plans
         * @param {number} [precinctId] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorWholesalePlansGet(precinctId?: number, search?: string, limit?: number, offset?: number, options?: any): (basePath?: string) => Promise<Array<WholesalePlan>> {
            const localVarFetchArgs = WholesalePlanApiFetchParamCreator(configuration).operatorWholesalePlansGet(precinctId, search, limit, offset, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
        /**
         * get wholesale_plan with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorWholesalePlansIdGet(id: number, options?: any): (basePath?: string) => Promise<WholesalePlan> {
            const localVarFetchArgs = WholesalePlanApiFetchParamCreator(configuration).operatorWholesalePlansIdGet(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response.json();
                    }
                });
            };
        },
    }
};

/**
 * WholesalePlanApi - factory interface
 * @export
 */
export const WholesalePlanApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * get wholesale_plans
         * @param {number} [precinctId] 
         * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
         * @param {number} [limit] records limit
         * @param {number} [offset] offset of list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorWholesalePlansGet(precinctId?: number, search?: string, limit?: number, offset?: number, options?: any) {
            return WholesalePlanApiFp(configuration).operatorWholesalePlansGet(precinctId, search, limit, offset, options)(basePath);
        },
        /**
         * get wholesale_plan with id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorWholesalePlansIdGet(id: number, options?: any) {
            return WholesalePlanApiFp(configuration).operatorWholesalePlansIdGet(id, options)(basePath);
        },
    };
};

/**
 * WholesalePlanApi - object-oriented interface
 * @export
 * @class WholesalePlanApi
 * @extends {BaseAPI}
 */
export class WholesalePlanApi extends BaseAPI {
    /**
     * get wholesale_plans
     * @param {number} [precinctId] 
     * @param {string} [search] keywords search, keywords spepetated by &#x27;,&#x27;
     * @param {number} [limit] records limit
     * @param {number} [offset] offset of list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WholesalePlanApi
     */
    public operatorWholesalePlansGet(precinctId?: number, search?: string, limit?: number, offset?: number, options?: any) {
        return WholesalePlanApiFp(this.configuration).operatorWholesalePlansGet(precinctId, search, limit, offset, options)(this.basePath);
    }

    /**
     * get wholesale_plan with id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WholesalePlanApi
     */
    public operatorWholesalePlansIdGet(id: number, options?: any) {
        return WholesalePlanApiFp(this.configuration).operatorWholesalePlansIdGet(id, options)(this.basePath);
    }

}
