











import { RetailPlanApiFp } from '@/api/api'
import Vue from 'vue'
export default Vue.extend({
  name:'retailplan-autocomplete',
  data(){
    return{
      temp: '',
      isLoading: true,
      items: []
    }
  },
  model:{
    prop: 'retailPlan',
    event: 'change'
  },
  async mounted(){
    this.$data.items = (await RetailPlanApiFp().operatorRetailPlansGet()()).map( plan => { return { text: plan.name, value: plan.id} } );
    this.isLoading = false;
  }
})
