
















































































































import { BuildingApiFp, PaymentMethod, PrecinctApiFp, RetailOrder, RetailOrderStatus, RetailOrderType, RetailService, RetailServiceApiFp, RetailServiceStatus, WholesalePlanContractTerm, WholesalePlanInvoiceFrequency } from '@/api/api'
import Vue from 'vue'
import NewConnection from './NewConnection.vue';

//TODO seperate form query into own components

export default Vue.extend({
  components:{NewConnection},
  data(){
    return{
      detailData: {
        isLoading: true,
        data: {}
      },
      precinct: {
        items: [''],
        isLoading: true
      },
      building: {
        items: [''],
        isLoading: true
      },
      searchData: {
        activeOnly: false,
        precinct: -1,
        building: -1,
        textQuery: '',
        limit: 100,
        offset: 1
      },
      isLoading: true,
      tableHeaders: [
        {value: 'data-table-expand'},
        {text: 'ID', value: 'id'},
        {text: 'Unit', value: 'unit.name'},
        {text: 'Customer', value: 'customer.fullName'},
        {text: 'Retail Plan', value: 'plan.name'},
        {text: 'Start Date', value: 'startDate'},
        {text: 'End Date', value: 'endDate'},
        {text: 'Status', value: 'status'},
        {text: '', value: 'op'}
      ],
      tableData: new Array<any>()
    }
  },
  methods:{
    async queryDetail(){

    },
    async queryTable(){
      this.isLoading = true;
      let query = (await RetailServiceApiFp().operatorRetailServicesGet(
        this.searchData.precinct,
        this.searchData.building,
        undefined,
        undefined,
        this.searchData.activeOnly ? RetailServiceStatus.Active : RetailServiceStatus.Inactive,
        this.searchData.textQuery,
        this.searchData.limit,
        this.searchData.offset
      )()) as Array<any>;
      this.tableData = query;
      this.isLoading = false;
    }
  },
  async mounted(){ // TODO try catch // TODO fix autogen api //TODO separate methods //TODO integrate auth to api
    let precincts = (await PrecinctApiFp().operatorPrecinctsGet()()).map( precinct => {return {'text': precinct.name, 'value': precinct.id}} ) as Array<any>;
    this.precinct.items = [{text: 'All', value: -1},...precincts];
    this.precinct.isLoading = false;
    let buildings = (await BuildingApiFp().operatorBuildingsGet()()).map( building => {return {'text': building.name, 'value': building.id}} ) as Array<any>;
    this.building.items = [{text: 'All', value: -1},...buildings];
    this.building.isLoading = false;

    this.queryTable();
  },
  watch: {
    'searchData.activeOnly': function(){this.queryTable()},
    'searchData.precinct': function(){this.queryTable()},
    'searchData.building': function(){this.queryTable()},
  }
})
